import React from 'react';
import './App.css';
import Nav from './components/NavBar';
import Header from './components/Hero';
import About from './components/About';
// import Hobbies from './components/Hobbies';
// import Experience from './components/Experience';
// import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ReactGA from 'react-ga';

const TRACKING_ID = "G-3GSEC83M7E";
ReactGA.initialize(TRACKING_ID);

function App() {
  // Available Colours:
  // blue, cyan, gray, green, orange, pink, purple, red, teal, yellow

  // edit this variable to change the color theme
  const color = "green";

  return (
    <>
      <Nav color={color} />
      <Header color={color} />
      <About color={color} />
      {/* <Hobbies color={color}/> */}
      {/* <Experience color={color} /> */}
      {/* <Projects color={color} /> */}
      <Contact color={color} />
      <Footer color={color}/>
    </>
  );
}

export default App;
